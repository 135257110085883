/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import Checkbox from '../../../components/shared/form-control/Checkbox';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import AuthService from '../../../services/AuthService';
import { FlowProps } from './RegisterFlow';
import { FieldRenderer } from '../../../components/user/FieldRenderer';
import { UserProfile } from '../../../models/AuthModels';
import { MemberFieldKey } from '../../../models/ClientMemberFields';
import i18next from 'i18next';

const RegisterDetails: FC<FlowProps> = (props) => {
  const { next, userEmail, memberFields, user } = props;
  const [formState, setFormState] = useState<UserProfile>({} as UserProfile);
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const [tcAgree, setTcAgree] = useState(false);

  const { t } = useTranslation(['auth', 'common']);

  useEffect(() => {
    setFormState({
      email: userEmail,
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      languageCode: user.language || 'en',
      phoneNumber: user.phoneNumber || '',
      address: user.address || undefined,
      officeLocation: user.officeLocation || undefined,
      employeeId: user.employeeId || '',
      startDate: user.startDate || undefined,
      birthday: user.birthday || undefined,
      departmentId: user.departmentId || undefined,
      groupId: user.groupId || undefined,
      positionId: user.positionId || undefined,
    });
  }, [user, userEmail]);

  const handleFieldChange = (key: string, value: any) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
    setErrors((prev) => ({ ...prev, [key]: '' }));
  };

  const validateField = useCallback(
    (key: string, value: any, isRequired: boolean): string | null => {
      if (isRequired && !value) {
        return t('common:validation.required');
      }
      return null;
    },
    [t],
  );

  const validateForm = useCallback(() => {
    const newErrors: Record<string, string> = {};

    const isValid = memberFields.every((field) => {
      // Skip validation for non-editable fields
      if (!field.isEditable) {
        return true;
      }

      const error = validateField(field.key, formState[field.key as keyof UserProfile], field.isRequired);

      if (error) {
        newErrors[field.key] = error;
        return false;
      }

      return true;
    });

    setErrors(newErrors);
    setIsFormValid(isValid && tcAgree);
  }, [formState, memberFields, tcAgree, validateField]);

  useEffect(() => {
    validateForm();
  }, [formState, memberFields, validateForm]);

  const saveDetails = useCallback(() => {
    if (isFormValid) {
      AuthService.setUserDetails({ ...formState, languageCode: i18next.language }).then(() => {
        next();
      });
    }
  }, [formState, isFormValid, next]);

  return (
    <>
      <Heading size={HeadingSize.H1}>{t('registration.details.heading')}</Heading>
      <div>{t('registration.details.subheading')}</div>
      <div>
        {memberFields.map((field) => {
          const renderer = FieldRenderer[field.key as MemberFieldKey];
          if (!renderer) return null;

          return (
            <div key={field.key} className="px-4">
              {renderer({
                field: { ...field, disabled: field.key === MemberFieldKey.Email },
                userProfile: formState,
                errors,
                onFieldChange: handleFieldChange,
                t,
              })}
            </div>
          );
        })}
        <div>
          <Checkbox
            value={tcAgree}
            onChange={setTcAgree}
            label={
              <Trans
                t={t}
                i18nKey="registration.details.inputs.t-and-c"
                components={{
                  Link: (
                    <a
                      href="/legal/terms-and-conditions"
                      data-cy="registration-terms-and-conditions-link"
                      className="cursor-pointer font-medium hover:underline"
                      target="_blank"
                    />
                  ),
                }}
              />
            }
            className="mt-4"
            data-cy="registration-terms-and-conditions"
          />
        </div>
        <div className="px-10 text-center">
          <Button className="my-6 w-full" type={ButtonType.PRIMARY} onClick={saveDetails} data-cy="register-next" disabled={!isFormValid}>
            <span className="font-medium">{t('registration.details.buttons.next')}</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegisterDetails;
