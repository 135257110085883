/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { ComponentRef, FC, useCallback, useMemo, useRef } from 'react';
import User from '../../models/User';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import NoFiltersSvg from '../../assets/images/empty-filters.svg';
import XIcon from '../shared/icon/XIcon';
import Badge from '../shared/badge/Badge';
import Popover from '../shared/popover/Popover';
import UserPermissionsTableFilterContent from './UserPermissionsTableFilterContent';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import EditIcon from '../shared/icon/EditIcon';

type Props = {
  table: Table<User>;
  filters: ColumnFiltersState;
  boolValues: Record<
    string,
    {
      true: string;
      false: string;
    }
  >;
};

const UserPermissionsFilterOverview: FC<Props> = (props) => {
  const { table, filters, boolValues } = props;
  const { t } = useTranslation(['organisation', 'table-view']);

  const filterColumnPopoverRef = useRef<Record<string, ComponentRef<typeof Popover>>>({});

  const columns = useMemo(() => {
    const cols = [];
    for (const filter of filters) {
      const column = table.getColumn(filter.id)!;
      const filterValue = Array.isArray(filter.value) ? filter.value : [filter.value];

      cols.push({ id: column.id, column, filterValue });
    }

    return cols;
  }, [filters, table]);

  const resetToDefaults = useCallback(() => {
    table.resetColumnFilters();
  }, [table]);

  return (
    <div className="min-w-128 max-w-[800px] px-2">
      <div className="flex items-center justify-between">
        <h3 className="text-dpm-18 font-semibold">{t('table-view:global-actions.filters-popover.title')}</h3>
        <Button type={ButtonType.TERTIARY} size={ButtonSize.S} onClick={resetToDefaults} disabled={!columns.length}>
          {t('table-view:global-actions.filters-popover.reset')}
        </Button>
      </div>
      {columns.length === 0 ? (
        <div className="text-center">
          <img src={NoFiltersSvg} alt="No active filters" className="mx-auto p-8" />
          <p className="text-gray-2 pt-4">{t('table-view:global-actions.filters-popover.empty')}</p>
        </div>
      ) : (
        <div className="mt-4 max-h-[450px] overflow-y-auto pr-2">
          {columns.map((col) => (
            <div key={col.id} className="mb-4">
              <div className="flex items-center justify-between">
                <div className="text-dpm-14 mb-1 font-medium">{t(`organisation:permissions.columns.${col.id}` as any)}</div>
                <div className="ml-4 flex items-center justify-end gap-2 pb-1">
                  <Popover
                    ref={(ref) => (filterColumnPopoverRef.current[col.id] = ref!)}
                    content={
                      <UserPermissionsTableFilterContent
                        column={col.column}
                        popoverHandles={filterColumnPopoverRef.current}
                        boolValues={boolValues[col.id]}
                      />
                    }
                    placement="bottom-start"
                  >
                    {(popover, toggle) => (
                      <span {...popover} {...mouseAndKeyboardCallbackProps(toggle)}>
                        <EditIcon className="h-5 w-5 flex-shrink-0 cursor-pointer" />
                      </span>
                    )}
                  </Popover>

                  <XIcon className="h-5 w-5 flex-shrink-0" onClick={() => col.column.setFilterValue(undefined)} />
                </div>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                {col.filterValue.map((value, i) => (
                  <Badge key={i} textClass="text-dpm-gray-2 !font-normal" text={`${value}`} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserPermissionsFilterOverview;
