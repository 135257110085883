/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from 'react';
import { Input } from '../shared/form-control/Input';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import TagSelector from '../tags/TagSelector';
import AddressInput from '../shared/form-control/AddressInput';
import PhoneInputWithCountrySelect from 'react-phone-number-input';
import CountryCodeSelect from '../shared/CountryCodeSelect';
import { DatePicker, DatePickerType } from '../shared/form-control/DatePicker';
import CalendarIcon from '../shared/icon/CalendarIcon';
import { ClientTagType } from '../../models/ClientTag';
import { MemberFieldKey, MemberField } from '../../models/ClientMemberFields';
import { Option } from '../Option';
import DateUtils from '../../utils/DateUtils';
import { TFunction, Namespace } from 'i18next';
import { ButtonType, ButtonSize } from '../shared/form-control/Button';
import { PhoneNumberInput } from '../form/action-types/phone-number/PhoneNumberAction';
import { UserProfile } from '../../models/AuthModels';

const RequiredAstrisk = () => <span className="text-semantic-3 pr-1 font-semibold">*</span>;

export type MemberFieldInput = {
  disabled?: boolean;
} & MemberField;

export const FieldRenderer: Record<
  MemberFieldKey,
  (props: {
    field: MemberFieldInput;
    userProfile: UserProfile & { role?: string };
    errors: Record<string, string | null>;
    onFieldChange: (key: string, value: any, isRequired: boolean) => void;
    t: TFunction<Namespace>;
    invitePermissionsOptions?: Option<string, string>[];
  }) => JSX.Element
> = {
  [MemberFieldKey.Firstname]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <Input
      key={field.key}
      data-cy="first-name"
      value={userProfile.firstName}
      onChange={(e) => onFieldChange(field.key, e.target.value, field.isRequired)}
      placeholder={t('common:add-or-invite-modal.invite.first-name')}
      label={t('common:add-or-invite-modal.invite.first-name')}
      maxLength={50}
      required={field.isRequired}
      error={errors[field.key] ? t(errors[field.key] as any) : ''}
      errorState={!!errors[field.key]}
      disabled={field.disabled}
    />
  ),
  [MemberFieldKey.LastName]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <Input
      key={field.key}
      data-cy="last-name"
      value={userProfile.lastName}
      onChange={(e) => onFieldChange(field.key, e.target.value, field.isRequired)}
      placeholder={t('common:add-or-invite-modal.invite.last-name')}
      label={t('common:add-or-invite-modal.invite.last-name')}
      maxLength={50}
      required={field.isRequired}
      error={errors[field.key] ? t(errors[field.key] as any) : ''}
      errorState={!!errors[field.key]}
      disabled={field.disabled}
    />
  ),
  [MemberFieldKey.Email]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <Input
      key={field.key}
      data-cy="email"
      value={userProfile.email}
      onChange={(e) => onFieldChange(field.key, e.target.value, field.isRequired)}
      placeholder={t('common:add-or-invite-modal.invite.email')}
      label={t('common:add-or-invite-modal.invite.email')}
      maxLength={100}
      required={field.isRequired}
      error={errors[field.key] ? t(errors[field.key] as any) : ''}
      errorState={!!errors[field.key]}
      disabled={field.disabled}
    />
  ),
  [MemberFieldKey.Role]: ({ field, userProfile, errors, onFieldChange, t, invitePermissionsOptions }) => (
    <DropdownSelect
      key={field.key}
      data-cy="platform-permission"
      wrapperClassName="mt-8 w-full"
      placeholder={t('common:add-or-invite-modal.invite.permission')}
      label={t('common:add-or-invite-modal.invite.permission')}
      aria-label={t('common:add-or-invite-modal.invite.permission')}
      options={invitePermissionsOptions || []}
      onChange={(o) => onFieldChange(field.key, o.value as string, field.isRequired)}
      value={{
        id: userProfile.role!,
        value: userProfile.role!,
        text: invitePermissionsOptions?.find((x) => x.value === userProfile.role)?.text || '',
      }}
      required
      error={errors[field.key] ? t(errors[field.key] as any) : ''}
      errorState={!!errors[field.key]}
      disabled={field.disabled}
    />
  ),
  [MemberFieldKey.Group]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <TagSelector
      key={field.key}
      onTagsChanged={(tags) => onFieldChange(field.key, tags[0]?.id, field.isRequired)}
      type={ClientTagType.UserGroup}
      placeholder={t('common:add-or-invite-modal.invite.group')}
      label={t('common:add-or-invite-modal.invite.group')}
      selectedTagIds={userProfile.groupId ? [userProfile.groupId] : []}
      isMulti={false}
      required={field.isRequired}
      error={errors[field.key] ? t(errors[field.key] as any) : ''}
      errorState={!!errors[field.key]}
      disabled={field.disabled}
    />
  ),
  [MemberFieldKey.Department]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <TagSelector
      key={field.key}
      onTagsChanged={(tags) => onFieldChange(field.key, tags[0]?.id, field.isRequired)}
      type={ClientTagType.UserDepartment}
      placeholder={t('common:add-or-invite-modal.invite.department')}
      label={t('common:add-or-invite-modal.invite.department')}
      selectedTagIds={userProfile.departmentId ? [userProfile.departmentId] : []}
      isMulti={false}
      required={field.isRequired}
      error={errors[field.key] ? t(errors[field.key] as any) : ''}
      errorState={!!errors[field.key]}
      disabled={field.disabled}
    />
  ),
  [MemberFieldKey.Position]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <TagSelector
      key={field.key}
      onTagsChanged={(tags) => onFieldChange(field.key, tags[0]?.id, field.isRequired)}
      type={ClientTagType.UserPosition}
      placeholder={t('common:add-or-invite-modal.invite.position')}
      label={t('common:add-or-invite-modal.invite.position')}
      selectedTagIds={userProfile.positionId ? [userProfile.positionId] : []}
      isMulti={false}
      required={field.isRequired}
      error={errors[field.key] ? t(errors[field.key] as any) : ''}
      errorState={!!errors[field.key]}
      disabled={field.disabled}
    />
  ),
  [MemberFieldKey.EmployeeId]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <Input
      key={field.key}
      data-cy="employee-id"
      value={userProfile.employeeId}
      onChange={(e) => onFieldChange(field.key, e.target.value, field.isRequired)}
      placeholder={t('common:add-or-invite-modal.invite.employee-id')}
      label={t('common:add-or-invite-modal.invite.employee-id')}
      maxLength={50}
      required={field.isRequired}
      error={errors[field.key] ? t(errors[field.key] as any) : ''}
      errorState={!!errors[field.key]}
      disabled={field.disabled}
    />
  ),
  [MemberFieldKey.PhoneNumber]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <Fragment key={field.key}>
      <div className="text-color-3 text-dpm-12 mt-4 transition-opacity duration-150 ease-in-out">
        {field.isRequired && <RequiredAstrisk />} {t('common:add-or-invite-modal.invite.phone')}
      </div>
      <PhoneInputWithCountrySelect
        international
        defaultCountry={'DE'}
        value={userProfile.phoneNumber || ''}
        onChange={(value) => onFieldChange(field.key, value, field.isRequired)}
        countrySelectComponent={(props) => (
          <div className="w-28">
            <CountryCodeSelect {...props} menuPosition="left" flagOnly={true} className={`rounded-r-none border-r-0`} />
          </div>
        )}
        inputComponent={PhoneNumberInput}
        numberInputProps={{
          isValid: !errors[field.key],
          errors: errors[field.key] ? [errors[field.key] as string] : [],
          required: field.isRequired,
        }}
        className="flex items-center"
        disabled={field.disabled}
      />
    </Fragment>
  ),
  [MemberFieldKey.Address]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <AddressInput
      key={field.key}
      data-cy="address"
      initialValue={userProfile.address?.address}
      onAddressChanged={(address) => onFieldChange(field.key, address, field.isRequired)}
      onClear={() => onFieldChange(field.key, null, field.isRequired)}
      placeholder={t('common:add-or-invite-modal.invite.address')}
      label={t('common:add-or-invite-modal.invite.address')}
      required={field.isRequired}
      error={errors[field.key] ? t(errors[field.key] as any) : ''}
      errorState={!!errors[field.key]}
      disabled={field.disabled}
    />
  ),
  [MemberFieldKey.OfficeLocation]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <AddressInput
      key={field.key}
      data-cy="office-location"
      initialValue={userProfile.officeLocation?.address}
      onAddressChanged={(address) => onFieldChange(field.key, address, field.isRequired)}
      onClear={() => onFieldChange(field.key, null, field.isRequired)}
      placeholder={t('common:add-or-invite-modal.invite.office-location')}
      label={t('common:add-or-invite-modal.invite.office-location')}
      required={field.isRequired}
      error={errors[field.key] ? t(errors[field.key] as any) : ''}
      errorState={!!errors[field.key]}
      disabled={field.disabled}
    />
  ),
  [MemberFieldKey.StartDate]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <Fragment key={field.key}>
      <div className="text-color-3 text-dpm-12 mt-4 transition-opacity duration-150 ease-in-out">
        {field.isRequired && <RequiredAstrisk />} {t('common:add-or-invite-modal.invite.start-date')}
      </div>
      <DatePicker
        key={field.key}
        data-cy="start-date"
        emptyWhenNull
        type={DatePickerType.BUTTON}
        buttonContent={
          userProfile.startDate ? DateUtils.formatDate(new Date(userProfile.startDate)) : t('common:add-or-invite-modal.invite.add-start-date')
        }
        buttonType={ButtonType.TERTIARY}
        buttonSize={ButtonSize.S}
        buttonIcon={<CalendarIcon className="h-4 w-4" />}
        buttonClassName="text-primary-1"
        notBefore={null}
        date={userProfile.startDate ? new Date(userProfile.startDate) : null}
        onChange={(date) => onFieldChange(field.key, date, field.isRequired)}
        disabled={field.disabled}
        error={errors[field.key] ? t(errors[field.key] as any) : ''}
        errorState={!!errors[field.key]}
      />
    </Fragment>
  ),
  [MemberFieldKey.Birthday]: ({ field, userProfile, errors, onFieldChange, t }) => (
    <Fragment key={field.key}>
      <div className="text-color-3 text-dpm-12 mt-4 transition-opacity duration-150 ease-in-out">
        {field.isRequired && <RequiredAstrisk />} {t('common:add-or-invite-modal.invite.birth-date')}
      </div>
      <DatePicker
        key={field.key}
        data-cy="birth-date"
        emptyWhenNull
        type={DatePickerType.BUTTON}
        buttonContent={
          userProfile.birthday ? DateUtils.formatDate(new Date(userProfile.birthday)) : t('common:add-or-invite-modal.invite.add-birth-date')
        }
        buttonType={ButtonType.TERTIARY}
        buttonSize={ButtonSize.S}
        buttonIcon={<CalendarIcon className="h-4 w-4" />}
        buttonClassName="text-primary-1"
        notBefore={null}
        notAfter={new Date()}
        date={userProfile.birthday ? new Date(userProfile.birthday) : null}
        onChange={(date) => onFieldChange(field.key, date, field.isRequired)}
        disabled={field.disabled}
        error={errors[field.key] ? t(errors[field.key] as any) : ''}
        errorState={!!errors[field.key]}
      />
    </Fragment>
  ),
  [MemberFieldKey.Status]: () => <></>,
};
