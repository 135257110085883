/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef, FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { getHighestRole, Roles } from '../../models/Role';
import User from '../../models/User';
import ClientService from '../../services/ClientService';
import { Option } from '../Option';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { Heading, HeadingSize } from '../shared/text/Heading';
import UserInvitation from '../../models/UserInvitation';
import InviteOrSearchModal from './InviteOrSearchModal';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import usePermissions from '../../hooks/permissions/usePermissions';
import { useCurrentClient } from '../../global-state/Clients';
import OrgMemberFieldsModal from '../my-org/OrgMemberFieldsModal';
import CogIcon from '../shared/icon/CogIcon';
import { MemberField } from '../../models/ClientMemberFields';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';
import UsersGroupSolidIcon from '../shared/icon/UsersGroupSolidIcon';
import Popover from '../shared/popover/Popover';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import Tooltip from '../shared/Tooltip';
import SearchIcon from '../shared/icon/SearchIcon';
import { Input, InputStyle } from '../shared/form-control/Input';
import FunnelIcon from '../shared/icon/FunnelIcon';
import SortIcon from '../shared/icon/SortIcon';
import {
  Cell,
  Column,
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  Header,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import SorterIcon from '../shared/icon/SorterIcon';
import Checkbox from '../shared/form-control/Checkbox';
import UserPermissionsTableFilterContent from './UserPermissionsTableFilterContent';
import Badge from '../shared/badge/Badge';
import StringUtils from '../../utils/StringUtils';
import ProfileAvatar, { ImageSize } from '../shared/profile-image/ProfileAvatar';
import UserPermissionsFilterOverview from './UserPermissionsFilterOverview';
import UserPermissionsSortingOverview from './UserPermissionsSortingOverview';
import { useShallow } from 'zustand/react/shallow';
import ContextMenu, { ContextMenuItem } from '../shared/ContextMenu';
import UserService from '../../services/UserService';
import AuthService from '../../services/AuthService';

const assignableRoles = Object.values(Roles).filter((x) => x !== Roles.SuperAdmin);
const EMPTY_ARRAY: never[] = [];

const CELL_IDS = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  builderAccess: 'builderAccess',
  role: 'role',
  status: 'status',
  address: 'address',
  birthDate: 'birthDate',
  department: 'department',
  employeeId: 'employeeId',
  group: 'group',
  officeLocation: 'officeLocation',
  position: 'position',
  phoneNumber: 'phoneNumber',
  startDate: 'startDate',
  profileImage: 'profileImage',
};

const UserPermissions: FC = () => {
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [memberFieldsModalOpen, setMemberFieldsModalOpen] = useState(false);
  const hasPermission = usePermissions();

  const { data: users = [], refetch: refetchUsers } = useFetchClientUsers(true);

  const { t } = useTranslation(['organisation', 'common', 'table-view']);
  const toasts = useToasts();
  const [currentClient, setClient] = useCurrentClient(useShallow((x) => [x.value, x.setValue]));

  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const totalFilters = useMemo(() => columnFilters.length, [columnFilters]);
  const totalSorts = useMemo(() => sorting.length, [sorting]);

  const filterColumnPopoverRef = useRef<Record<string, ComponentRef<typeof Popover>>>({});

  const inviteUser = (userToInvite: UserInvitation) => {
    if (!currentClient?.id) {
      toasts.addToast({
        title: t('organisation:permissions.toasts.invite-failed'),
        type: ToastType.ERROR,
      });
    } else {
      ClientService.inviteUser(currentClient?.id, userToInvite)
        .then((res) => {
          if (res.data.isNewUser) {
            toasts.addToast({
              title: t('organisation:permissions.toasts.invited'),
              description: t('organisation:permissions.toasts.invited-desc', {
                email: userToInvite?.email,
                client: currentClient?.name,
              }),
              type: ToastType.SUCCESS,
              expiresInMs: 5000,
            });
          } else {
            toasts.addToast({
              title: t('organisation:permissions.toasts.invite-failed'),
              description: t('organisation:permissions.toasts.invite-failed-desc'),
              type: ToastType.INFO,
              expiresInMs: 10000,
            });
          }
          refetchUsers();
          setInviteUserModalOpen(false);
        })
        .catch((err) => {
          toasts.addToast({
            title: t('organisation:permissions.toasts.invite-failed'),
            description: err?.data?.meta?.message,
            type: ToastType.ERROR,
          });
        });
    }
  };

  const removeUser = useCallback(
    (user: User) => {
      if (currentClient?.id && user.id) {
        ClientService.removeUser(currentClient?.id, user.id).then((res) => {
          if (res.data) {
            refetchUsers();
            toasts.addToast({
              title: t('organisation:permissions.toasts.removed'),
              description: t('organisation:permissions.toasts.removed-desc', {
                email: user.email,
                client: currentClient?.name,
              }),
              type: ToastType.SUCCESS,
              expiresInMs: 5000,
            });
          }
        });
      }
    },
    [currentClient?.id, currentClient?.name, refetchUsers, t, toasts],
  );

  const disableUser = useCallback(
    (user: User) => {
      UserService.disableUser(user.id).then(() => {
        toasts.addToast({ title: t('organisation:permissions.toasts.disabled'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        refetchUsers();
      });
    },
    [refetchUsers, t, toasts],
  );

  const activateUser = useCallback(
    (user: User) => {
      UserService.enableUser(user.id).then(() => {
        toasts.addToast({ title: t('organisation:permissions.toasts.activated'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        refetchUsers();
      });
    },
    [refetchUsers, t, toasts],
  );

  const resetUserPassword = useCallback(
    (user: User) => {
      if (user?.email) {
        AuthService.forgotPassword(user.email as string).then(() => {
          toasts.addToast({ title: t('common:permissions.toasts.reset-password'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        });
      }
    },
    [t, toasts],
  );

  const resetUser2Fa = useCallback(
    (user: User) => {
      if (user?.id) {
        UserService.reset2Fa(user.id as string).then(() => {
          toasts.addToast({ title: t('common:permissions.toasts.reset-2fa'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        });
      }
    },
    [t, toasts],
  );

  const onInviteClose = useCallback(() => {
    setInviteUserModalOpen(false);
  }, []);

  const inviteRoleFilter = useCallback((role: Option<string, string>) => {
    return assignableRoles.indexOf(role.value as (typeof assignableRoles)[number]) > -1;
  }, []);

  const displayedCells = useMemo(() => {
    return [CELL_IDS.profileImage, CELL_IDS.firstName, CELL_IDS.lastName, CELL_IDS.email, CELL_IDS.role, CELL_IDS.builderAccess, CELL_IDS.status];
  }, []);

  const columnFilterFn = useCallback((row: Row<User>, columnId: string, filterValue: string | number | (string | number)[]) => {
    const value = row.getValue<any>(columnId).toString();

    if (Array.isArray(filterValue)) {
      return filterValue.length === 0 || filterValue.some((x) => value === x.toString());
    }

    return value === filterValue.toString();
  }, []);

  const columnDefinitions = useMemo<ColumnDef<User>[]>(
    () => [
      {
        id: CELL_IDS.profileImage,
        accessorFn: (row) => row,
        size: 65,
        enableSorting: false,
        enableGlobalFilter: false,
        enableColumnFilter: false,
        enableResizing: false,
      },
      {
        id: CELL_IDS.firstName,
        accessorKey: 'firstName',
        sortingFn: 'text',
        filterFn: 'pbkFilter' as any,
      },
      {
        id: CELL_IDS.lastName,
        accessorKey: 'lastName',
        sortingFn: 'text',
        filterFn: 'pbkFilter' as any,
      },
      {
        id: CELL_IDS.email,
        accessorKey: 'email',
        sortingFn: 'alphanumeric',
        filterFn: 'pbkFilter' as any,
      },
      {
        id: CELL_IDS.builderAccess,
        accessorFn: (row) => !!row?.isArchitect,
        sortingFn: 'basic',
        enableGlobalFilter: false,
        filterFn: 'pbkFilter' as any,
      },
      {
        id: CELL_IDS.role,
        accessorFn: (row) => {
          if (!row.roles || !currentClient?.id) return null;

          const role = getHighestRole(row.roles[currentClient.id] ?? []);
          return t(`common:roles.${role}`).toString();
        },
        sortingFn: 'text',
        enableGlobalFilter: false,
        filterFn: 'pbkFilter' as any,
      },
      {
        id: CELL_IDS.status,
        accessorFn: (row) => !!row?.active,
        sortingFn: 'basic',
        enableGlobalFilter: false,
        filterFn: 'pbkFilter' as any,
      },
    ],
    [currentClient?.id, t],
  );

  const tableColumns = useMemo<ColumnDef<User>[]>(() => {
    return displayedCells.map((cellId) => columnDefinitions.find((x) => x.id === cellId)!);
  }, [columnDefinitions, displayedCells]);

  const table = useReactTable({
    data: users ?? EMPTY_ARRAY,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    defaultColumn: {
      // minus sidebar, minus page padding, minus profile picture column
      size: (window.innerWidth - 72 - 17 * 2 - 65) / (tableColumns.length - 1),
    },
    enableColumnResizing: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    enableSortingRemoval: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: true,
    state: {
      sorting,
      globalFilter: searchTerm,
      columnFilters,
    },
    onSortingChange: setSorting,
    isMultiSortEvent: () => true,
    enableGlobalFilter: true,
    globalFilterFn: 'auto',
    onGlobalFilterChange: setSearchTerm,
    onColumnFiltersChange: setColumnFilters,
    filterFns: {
      pbkFilter: columnFilterFn,
    },
  });

  const headerCellContent = useCallback(
    (header: Header<User, unknown>) => {
      if (header.id === CELL_IDS.profileImage) return <div>&nbsp;</div>;

      const content = t(`organisation:permissions.member-fields-config.fields.${header.id}` as any);

      return (
        <Tooltip text={content} truncatedTextMode>
          {(tooltip) => (
            <div className="select-none truncate" {...tooltip}>
              {content}
            </div>
          )}
        </Tooltip>
      );
    },
    [t],
  );

  const dataCellContent = useCallback(
    (cell: Cell<User, unknown>) => {
      if (cell.column.id === CELL_IDS.builderAccess) {
        return <Checkbox value={cell.getValue() as boolean} />;
      } else if (cell.column.id === CELL_IDS.status) {
        const active = cell.getValue() as boolean;
        return (
          <Badge
            backgroundClass={active ? 'bg-semantic-1 bg-opacity-15' : 'bg-gray-5 bg-opacity-75'}
            textClass={active ? 'text-semantic-1' : 'text-gray-2'}
            text={t(`organisation:permissions.user-status.${active ? 'active' : 'inactive'}`)}
          />
        );
      } else if (cell.column.id === CELL_IDS.profileImage) {
        return (
          <div className="flex items-center justify-center">
            <ProfileAvatar user={cell.getValue() as User} size={ImageSize.S} />
          </div>
        );
      }

      if (searchTerm && cell.column.getCanGlobalFilter()) {
        const value = cell.getValue() as string;
        return <span>{StringUtils.highlightText(value, searchTerm)}</span>;
      }

      return flexRender(cell.column.columnDef.cell, cell.getContext());
    },
    [searchTerm, t],
  );

  const columnStyles = useCallback((column: Column<User>) => {
    const isResizing = column.getIsResizing();
    let boxShadow = '';

    if (isResizing) {
      if (boxShadow) boxShadow += ', ';

      boxShadow += 'inset -2px 0px 0px 0px rgba(var(--color-accent-1), 1)';
    }

    // Bottom "border"
    if (boxShadow) boxShadow += ', ';
    boxShadow += 'inset 0 -1px rgba(var(--color-gray-5), 1)';

    return {
      width: column.getSize(),
      maxWidth: column.getSize(),
      boxShadow,
    };
  }, []);

  const boolFilterText = useMemo(() => {
    return {
      [CELL_IDS.status]: {
        true: t('organisation:permissions.user-status.active'),
        false: t('organisation:permissions.user-status.inactive'),
      },
    };
  }, [t]);

  const contextItems = useCallback(
    (user: User) => {
      return [
        {
          title: t('organisation:permissions.context-menu.reset-password'),
          onClick: () => {
            resetUserPassword(user);
          },
          hide: !user.active,
        },
        {
          title: t('organisation:permissions.context-menu.reset-2fa'),
          onClick: () => {
            resetUser2Fa(user);
          },
          hide: !user.active,
        },
        {
          hasDivider: true,
          disabled: true,
          hide: !user.active,
        },
        {
          customRenderer: () => {
            return <span className="text-semantic-2">{t('organisation:permissions.context-menu.remove')}</span>;
          },
          onClick: () => {
            removeUser(user);
          },
        },
        {
          customRenderer: () => {
            return <span className="text-semantic-2">{t('organisation:permissions.context-menu.deactivate')}</span>;
          },
          hide: !hasPermission(Roles.SuperAdmin) || !user.active,
          onClick: () => {
            disableUser(user);
          },
        },
        {
          title: t('organisation:permissions.context-menu.activate'),
          onClick: () => {
            activateUser(user);
          },
          hide: !hasPermission(Roles.SuperAdmin) || user.active,
        },
      ] as ContextMenuItem[];
    },
    [activateUser, disableUser, hasPermission, removeUser, resetUser2Fa, resetUserPassword, t],
  );

  const buttonContainerClasses =
    'flex items-center justify-center rounded-md bg-gray-6 text-gray-2 max-h-[30px] h-[30px] min-w-[30px] px-1 cursor-pointer hover:bg-gray-5';
  const buttonClasses = `${buttonContainerClasses} w-9 h-8 py-1 `;

  return (
    <div className="flex h-full flex-col pt-6">
      <div className="flex items-center justify-between pb-3">
        <Heading size={HeadingSize.H4}>{t('organisation:permissions.heading')}</Heading>
        <div className="flex gap-4">
          <Button
            data-cy="show-invite-modal"
            disabled={!hasPermission(Roles.TeamLead)}
            type={ButtonType.PRIMARY}
            size={ButtonSize.S}
            onClick={() => setInviteUserModalOpen(true)}
          >
            <Button.Slot name="Icon">
              <UsersGroupSolidIcon className="h-4 w-4 text-white" />
            </Button.Slot>
            {t('common:permissions.buttons.invite')}
          </Button>
        </div>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Heading size={HeadingSize.H6} className="font-medium">
            {t('organisation:permissions.subHeading')}
          </Heading>
          <span className="text-gray-4 mt-[2px] font-bold"> • </span>
          <span className="text-dpm-14 mt-[4px]">
            {t('organisation:permissions.result-count', { count: table.getRowCount(), total: users?.length ?? 0 })}
          </span>
        </div>

        <div className={`flex flex-shrink-0 items-center gap-2 pr-1`}>
          <div className="flex items-center">
            <div
              className={`overflow-hidden transition-all duration-300 ease-in-out ${isSearchExpanded ? 'mr-2 w-64 px-1 opacity-100' : 'mr-0 w-0 px-0 opacity-0'} `}
            >
              <Input
                style={InputStyle.MINIMAL}
                placeholder={t('organisation:permissions.search.placeholder')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClear={() => setSearchTerm('')}
                autoFocus={isSearchExpanded}
              />
            </div>
            <Tooltip text={t('organisation:permissions.tooltips.search')}>
              {(tooltip) => (
                <div {...tooltip}>
                  {
                    <SearchIcon
                      className={`${buttonClasses} ${searchTerm.length > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                      onClick={() => setIsSearchExpanded((prev) => !prev)}
                    />
                  }
                </div>
              )}
            </Tooltip>
          </div>

          <div className={`flex flex-shrink-0 items-center gap-2 !pr-1`}>
            <Popover
              ref={(ref) => (filterColumnPopoverRef.current['filtering'] = ref!)}
              content={<UserPermissionsFilterOverview table={table} filters={columnFilters} boolValues={boolFilterText} />}
              placement="bottom-end"
            >
              {(popover, toggle) => (
                <div
                  {...popover}
                  className={`${buttonContainerClasses} ${totalFilters > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                  {...mouseAndKeyboardCallbackProps(toggle)}
                >
                  <Tooltip text={t('organisation:permissions.tooltips.filter')}>
                    {(tooltip) => <div {...tooltip}>{<FunnelIcon className="h-6 w-5" />}</div>}
                  </Tooltip>
                  {totalFilters > 0 && <div className="px-2 font-medium">{t('table-view:filters.count', { count: totalFilters })}</div>}
                </div>
              )}
            </Popover>

            <Popover content={<UserPermissionsSortingOverview table={table} columnSorting={sorting} />} placement="bottom-end">
              {(popover, toggle) => (
                <div
                  {...popover}
                  className={`${buttonContainerClasses} ${totalSorts > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
                  {...mouseAndKeyboardCallbackProps(toggle)}
                >
                  <Tooltip text={t('organisation:permissions.tooltips.sorting')}>
                    {(tooltip) => <div {...tooltip}>{<SortIcon className="h-6 w-5" />}</div>}
                  </Tooltip>
                  {totalSorts > 0 && <div className="px-2 font-medium">{t('table-view:sorting.count', { count: totalSorts })}</div>}
                </div>
              )}
            </Popover>

            <div className={`${buttonContainerClasses}`} {...mouseAndKeyboardCallbackProps(() => setMemberFieldsModalOpen(true))}>
              <Tooltip text={t('organisation:permissions.tooltips.manage-fields')}>
                {(tooltip) => <div {...tooltip}>{<CogIcon className="h-6 w-5" />}</div>}
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <div className="-mx-6 flex flex-1 flex-col p-6">
        {users.length ? (
          <div
            style={{
              width: table.getTotalSize(),
            }}
            className={`max-h-full min-w-full max-w-0 overflow-x-hidden`}
          >
            <div className="sticky top-0 z-10 w-fit">
              {table.getHeaderGroups().map((headerGroup) => (
                <div
                  key={headerGroup.id}
                  style={{
                    width: table.getTotalSize(),
                  }}
                  className="border-gray-5 flex w-fit items-center border border-b-0"
                >
                  {headerGroup.headers.map((header: Header<User, unknown>, i) => (
                    <div
                      key={header.id}
                      style={columnStyles(header.column)}
                      className={`hover:bg-background-1 group relative ${i !== 0 ? 'border-l' : 'border-0'} border-gray-5 bg-white py-3 pl-2 pr-6 text-left font-normal`}
                    >
                      <div className="flex items-center justify-between gap-1">
                        {header.column.getCanSort() && (
                          <div
                            className={'w-4 cursor-pointer select-none'}
                            {...mouseAndKeyboardCallbackProps(header.column.getToggleSortingHandler())}
                          >
                            <SorterIcon className="text-accent-1 -mt-1 h-6 w-5" direction={header.column.getIsSorted() || 'none'} />
                          </div>
                        )}
                        <div className="flex-1">{header.isPlaceholder ? null : headerCellContent(header)}</div>
                        {header.column.getCanFilter() && (
                          <div className={`truncate ${header.column.getCanFilter() ? 'cursor-pointer' : ''}`}>
                            <Popover
                              ref={(ref) => (filterColumnPopoverRef.current[header.id] = ref!)}
                              content={
                                <UserPermissionsTableFilterContent
                                  column={header.column}
                                  popoverHandles={filterColumnPopoverRef.current}
                                  boolValues={boolFilterText[header.column.id]}
                                />
                              }
                              placement="bottom-start"
                            >
                              {(popover, toggle) => (
                                <span {...popover} {...mouseAndKeyboardCallbackProps(toggle)}>
                                  <FunnelIcon className="text-gray-2 hover:border-primary-2 hover:bg-primary-2 float-right h-6 w-6 flex-shrink-0 rounded-md border border-transparent p-1 hover:bg-opacity-25" />
                                </span>
                              )}
                            </Popover>
                          </div>
                        )}
                      </div>
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className={`w-fit items-center bg-white`}>
              {table.getRowModel().rows.map((row, rowIndex) => {
                return (
                  <div
                    key={`row-${rowIndex}`}
                    style={{
                      width: table.getTotalSize(),
                    }}
                    className="group/row border-gray-5 relative flex items-stretch border-x"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <div
                        key={cell.id}
                        style={columnStyles(cell.column)}
                        className={`group-hover/row:bg-background-1 relative flex flex-col justify-center whitespace-nowrap bg-white pl-2 pr-6`}
                      >
                        <div className="text-dpm-14 truncate py-2 text-gray-500">{dataCellContent(cell)}</div>
                      </div>
                    ))}
                    {(selectedRowIndex === null || selectedRowIndex === rowIndex) && (
                      <div
                        className={`absolute bottom-0 right-4 top-0 z-10 flex items-center px-[2px] ${selectedRowIndex !== rowIndex ? 'opacity-0' : ''} transition-opacity duration-200 group-hover/row:opacity-100`}
                      >
                        <ContextMenu items={contextItems(row.original)} onMenuToggle={(value) => setSelectedRowIndex(value ? rowIndex : null)} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div data-cy="user-empty" className="flex h-full justify-center">
            <div className="mt-36 text-center">
              <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
              <Heading size={HeadingSize.H3} className="my-4">
                {t('common:permissions.no-users-found')}
              </Heading>
            </div>
          </div>
        )}
      </div>

      <InviteOrSearchModal
        open={inviteUserModalOpen}
        onClose={onInviteClose}
        onInviteNew={inviteUser}
        filterRoles={inviteRoleFilter}
        clientId={currentClient?.id}
      />
      {currentClient && (
        <OrgMemberFieldsModal
          open={memberFieldsModalOpen}
          config={currentClient?.memberFields ?? []}
          onClose={() => {
            setMemberFieldsModalOpen(false);
          }}
          onSave={(config: MemberField[]) => {
            ClientService.updateClient(currentClient.id, { ...currentClient, memberFields: config }).then((res) => {
              toasts.addToast({ title: t('details.toasts.saved'), type: ToastType.SUCCESS, expiresInMs: 5000 });
              setClient({ ...currentClient, ...res });
            });
          }}
        />
      )}
    </div>
  );
};

export default UserPermissions;
