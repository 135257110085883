import { Column } from '@tanstack/react-table';
import { ComponentRef, FC, useCallback, useMemo, useState } from 'react';
import User from '../../models/User';
import { SearchInput } from '../shared/form-control/SearchInput';
import { InputStyle } from '../shared/form-control/Input';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { useTranslation } from 'react-i18next';
import Checkbox from '../shared/form-control/Checkbox';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import Popover from '../shared/popover/Popover';

type Props = {
  column: Column<User>;
  popoverHandles: Record<string, ComponentRef<typeof Popover>>;
  boolValues?: { true: string; false: string };
};

const UserPermissionsTableFilterContent: FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'table-view']);
  const { column, boolValues = { true: t('on'), false: t('off') }, popoverHandles } = props;

  const [selectedValues, setSelectedValues] = useState<string[]>((column.getFilterValue() as string[]) ?? []);
  const [search, setSearch] = useState('');
  const [dirty, setDirty] = useState(false);

  const uniqueOptions = useMemo(() => {
    return Array.from(column.getFacetedUniqueValues().keys())
      .sort()
      .map((x) => ({ id: x, text: typeof x === 'boolean' ? (x ? boolValues.true : boolValues.false) : x }));
  }, [boolValues.false, boolValues.true, column]);

  const filteredOptions = useMemo(() => {
    const s = search.toLowerCase();
    return uniqueOptions.filter((option) => option.text.toLowerCase().includes(s));
  }, [search, uniqueOptions]);

  const selectAll = useCallback(() => {
    setSelectedValues(uniqueOptions.map((x) => x.id));
    setDirty(true);
  }, [uniqueOptions]);

  const clearAll = useCallback(
    (applyAndClose?: boolean) => {
      setSelectedValues([]);
      setDirty(true);

      if (applyAndClose) {
        column.setFilterValue(undefined);
        popoverHandles?.[column.id]?.closePopover();
      }
    },
    [column, popoverHandles],
  );

  const selectOption = useCallback((option: string, selected: boolean) => {
    setDirty(true);

    if (selected) {
      setSelectedValues((prev) => [...prev, option]);
    } else {
      setSelectedValues((prev) => prev.filter((x) => x !== option));
    }
  }, []);

  const applyFilter = useCallback(() => {
    column.setFilterValue(selectedValues.length > 0 ? selectedValues : undefined);
    popoverHandles?.['filtering']?.closePopover();
    popoverHandles?.[column.id]?.closePopover();
  }, [column, popoverHandles, selectedValues]);

  return (
    <div>
      <div className="-mx-2 my-2">
        <SearchInput style={InputStyle.MINIMAL} value={search} onChange={(e) => setSearch(e.target.value)} />
      </div>
      <div className="text-dpm-14 my-1 flex flex-row justify-between font-medium">
        <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(selectAll)} data-cy="select-all">
          {t('list.filter.select-all')}
        </span>
        <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => clearAll())} data-cy="clear-all">
          {t('list.filter.clear-all')}
        </span>
      </div>

      <div className="-mr-4 h-64 w-72 space-y-4 overflow-y-auto">
        {filteredOptions.map((x) => (
          <Checkbox key={x.id} value={selectedValues.includes(x.id)} onChange={(v) => selectOption(x.id, v)} label={x.text} />
        ))}
      </div>

      <div className="-mx-4 -mb-2 mt-4 flex items-center justify-end gap-2 px-2">
        <Button type={ButtonType.SECONDARY} size={ButtonSize.S} onClick={() => clearAll(true)} disabled={selectedValues.length === 0}>
          {t('table-view:filter-menu.clear')}
        </Button>
        <Button type={ButtonType.PRIMARY} size={ButtonSize.S} onClick={applyFilter} disabled={!dirty}>
          {t('table-view:filter-menu.apply')}
        </Button>
      </div>
    </div>
  );
};

export default UserPermissionsTableFilterContent;
