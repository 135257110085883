import { ApiResponse } from '../models/ApiResponse';
import User from '../models/User';
import UserInvitation, { UserInviteResponse } from '../models/UserInvitation';
import BaseService from './BaseService';

class UserService extends BaseService {
  public static inviteUser(invite: UserInvitation): Promise<ApiResponse<UserInviteResponse>> {
    return this.post(`/v1/users/invite`, invite);
  }

  public static resendInvite(userId: string, accountId: string): Promise<ApiResponse<boolean>> {
    return this.post(`/v1/users/${userId}/accounts/${accountId}/resendInvite`);
  }

  public static enableUser(userId: string): Promise<ApiResponse<boolean>> {
    return this.post(`/v1/users/${userId}/activate`);
  }

  public static disableUser(userId: string): Promise<ApiResponse<boolean>> {
    return this.post(`/v1/users/${userId}/deactivate`);
  }

  public static getUserRoles(userId: string, accountId: string): Promise<ApiResponse<string[]>> {
    return this.get(`/v1/users/${userId}/roles`, { params: { accountId } });
  }

  public static updateRole(userId: string, clientId: string, role: string): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/users/${userId}/role`, { clientId, role });
  }

  public static updateArchitectPermission(userId: string, enable: boolean): Promise<ApiResponse<boolean>> {
    return this.put(`/v1/users/${userId}/architect`, { enable });
  }

  public static reset2Fa(userId: string): Promise<ApiResponse<User>> {
    return this.post(`/v1/users/${userId}/reset2FA`);
  }
}

export default UserService;
