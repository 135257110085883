/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, ReactElement, useEffect, memo, useImperativeHandle, forwardRef } from 'react';
import {
  useFloating,
  offset,
  shift,
  flip,
  Placement,
  useDismiss,
  size,
  useClick,
  useFocus,
  useInteractions,
  useRole,
  autoUpdate,
} from '@floating-ui/react';

interface PopoverProps {
  content: React.ReactNode;
  children: (props: Record<string, unknown>, toggle: () => void, isOpen: boolean, close: () => void) => ReactElement;
  placement?: Placement;
  className?: string;
  onToggle?: (isOpen: boolean) => void;
}

export interface PopoverHandle {
  closePopover: () => void;
}

const Popover = forwardRef<PopoverHandle, PopoverProps>(function Popover(props, ref) {
  const { content, children, placement = 'bottom', className = '', onToggle } = props;
  const [isOpen, setIsOpen] = useState(false);
  const referenceRef = useRef<any>(null);

  useEffect(() => {
    onToggle?.(isOpen);
  }, [isOpen, onToggle]);

  const { floatingStyles, refs, context } = useFloating({
    open: isOpen,
    onOpenChange: (o) => !o && setIsOpen(!isOpen),
    placement,
    middleware: [offset(10), flip(), shift(), size()],
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
  });

  const click = useClick(context, { keyboardHandlers: false });
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const focus = useFocus(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role, focus]);

  const togglePopover = () => {
    setIsOpen((prev) => !prev);
  };

  const closePopover = () => {
    setIsOpen(false);
  };

  useImperativeHandle(ref, () => ({
    closePopover,
  }));

  return (
    <>
      {children(
        {
          ref: referenceRef.current ? undefined : refs.setReference,
          ...getReferenceProps(),
        },
        togglePopover,
        isOpen,
        closePopover,
      )}
      {isOpen && (
        <div
          ref={refs.setFloating}
          style={{ ...floatingStyles }}
          {...getFloatingProps()}
          className={`z-50 cursor-default rounded-md bg-white p-4 shadow-lg ${className}`}
        >
          {content}
        </div>
      )}
    </>
  );
});

export default memo(Popover);
