/* eslint-disable @typescript-eslint/no-explicit-any */
import { browserTracingIntegration, replayIntegration } from '@sentry/browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FC, lazy, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { LatestNotificationsProvider } from './contexts/LatestNotificationsContext';
import { ToastProvider } from './contexts/ToastContext';
import i18n from './i18n';
import IntercomUtils from './utils/IntercomUtils';
import * as Sentry from '@sentry/react';
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from 'react-router-dom';

const App = lazy(() => import('./App'));
import './assets/tailwind.css';
import('./chartjsPlugins.js');
import { DebugHotkeyProvider } from './contexts/DebugHotkeyContext';
import { PbkAuth } from './contexts/PbkAuthContext';
import { LayoutProvider } from './contexts/LayoutContext';

import { PostHogProvider } from 'posthog-js/react';
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const postHogOptions = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  session_recording: {
    maskAllInputs: true,
    maskInputFn: (text: string, element: any) => {
      /**
       * For passwords, it is important to note that "click to show password" buttons typically turn the input type to text.
       * This would then reveal the password. Thus instead of checking for type='password', we need to check a different field,
       * like id:
       */
      const maskTypes = ['email', 'password'];

      if (maskTypes.indexOf(element?.attributes['type']?.value) !== -1 || maskTypes.indexOf(element?.attributes['id']?.value) !== -1) {
        return '*'.repeat(text.length);
      }
      return text;
    },
    maskTextSelector: '*',
    maskTextFn(text: string, element: any) {
      if (element?.dataset['sensitive'] === 'true') {
        return '*'.repeat(text.length);
      }
      const emailRegex = /(\S+)@(\S+\.\S+)/g;
      return text.replace(emailRegex, (match: any, g1: any, g2: any) => {
        return '*'.repeat(g1.length) + '@' + '*'.repeat(g2.length);
      });
    },
  },
};

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN as string,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT as string,
    beforeSend(event) {
      if (event.user) {
        delete event.user.email;
      }
      if (event.extra?.dialog) {
        Sentry.showReportDialog({
          eventId: event.event_id,
        });
      }
      return event;
    },
    autoSessionTracking: true,
    integrations: [
      replayIntegration(),
      browserTracingIntegration({
        instrumentNavigation: true,
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({ useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes }),
    ],
    attachStacktrace: true,
    sampleRate: 1.0,
    debug: !!import.meta.hot,
    normalizeDepth: 10,

    // If there are errors that do not originate from our app, consider decluttering
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
    denyUrls: [],
    allowUrls: [],
    ignoreErrors: ['Non-Error exception captured', 'Non-Error promise rejection captured', 'CanceledError'],

    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

/**
 * Initialize Intercom
 */
IntercomUtils.initialize();

const queryClient = new QueryClient();

const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
  {
    path: '*',
    Component: () => (
      <PbkAuth>
        <LatestNotificationsProvider>
          <App />
        </LatestNotificationsProvider>
      </PbkAuth>
    ),
  },
]);

export const gqlState = {
  clientId: '',
  tenantId: '',
};

const apolloHttpLink = createHttpLink({
  uri: import.meta.env.VITE_API_BASE_URL + '/graphql',
  credentials: 'include',
});

const apolloAuthLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-client-id': gqlState.clientId,
      'x-tenant-id': gqlState.tenantId,
    },
  };
});

const gqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: apolloAuthLink.concat(apolloHttpLink),
  connectToDevTools: true,
});

const IndexWrapped: FC = () => (
  <DebugHotkeyProvider>
    <I18nextProvider i18n={i18n}>
      <ToastProvider>
        <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={postHogOptions}>
          <QueryClientProvider client={queryClient}>
            <ApolloProvider client={gqlClient}>
              <ReactQueryDevtools initialIsOpen={false} position="bottom" />
              <LayoutProvider>
                <RouterProvider router={router} />
              </LayoutProvider>
            </ApolloProvider>
          </QueryClientProvider>
        </PostHogProvider>
      </ToastProvider>
    </I18nextProvider>
  </DebugHotkeyProvider>
);

export default IndexWrapped;
