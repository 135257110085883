import { FC, lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import PageLoader from '../../../components/shared/page-loader/PageLoader';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import useUnauthenticate from '../../../hooks/useUnauthenticate';
import AuthService from '../../../services/AuthService';
import BaseService from '../../../services/BaseService';
import { decodeJWT, extractFromJWT, isJWTValid } from '../../../utils/JwtUtils';
import { defaultMemberFields, MemberField } from '../../../models/ClientMemberFields';
import User from '../../../models/User';
import { useRegistrationClientId } from '../../../global-state/Clients';

const RegisterLanding = lazy(() => import('./RegisterLanding'));
const RegisterDetails = lazy(() => import('./RegisterDetails'));
const SetPassword = lazy(() => import('./SetPassword'));
const TwoFAInfo = lazy(() => import('./TwoFAInfo'));
const Set2FA = lazy(() => import('./Set2FA'));
/// START CONFIGURATION

// Map of checkpoint to screens in it
const Checkpoints = {
  'setup-account-details': ['not-started', 'set-account-details'] as const,
  'setup-password': ['set-password'] as const,
  'setup-2FA': ['2fa-info', 'set-2fa'] as const,
};

// The order in which checkpoints should be displayed
const CheckpointsOrder: (keyof typeof Checkpoints)[] = ['setup-account-details', 'setup-password', 'setup-2FA'];

/// END CONFIGURATION

// START MAGIC TYPESCRIPT
type ExtractReturnTypes<T> = T extends IterableIterator<infer R> ? R : never;
type Steps = ExtractReturnTypes<ReturnType<(typeof Checkpoints)[keyof typeof Checkpoints]['values']>>;
// END MAGIC TYPESCRIPT

export type FlowProps = {
  userEmail: string;
  user: User;
  memberFields: MemberField[];
  next: () => void;
  push: (step: Steps) => void;
};

const RegisterFlow: FC = () => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const token = useMemo(() => query.get('token'), [query]);
  const setRegistrationClientId = useRegistrationClientId((x) => x.setValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasToken = useMemo(() => !!token, []);
  // Only check token once, to avoid flashing of "invalid token" when logging out
  // token should be present when register via email,
  // when not present is when the app redirects the user her to for example to reset 2fa
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tokenValid = useMemo(() => (hasToken && isJWTValid(token)) || !hasToken, []);

  const accountId = useMemo(() => {
    if (token) {
      return extractFromJWT(decodeJWT(token), 'account_id') as string;
    }
  }, [token]);

  const [userEmail, setUserEmail] = useState('');
  const [user, setUser] = useState<User>({} as User);
  const [memberFields, setMemberFields] = useState<MemberField[]>([]);
  const initialLoad = useRef(true);
  const logout = useUnauthenticate();

  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  // Stack of steps to complete, top-most step is displayed, then popped when next() is called inside the step
  const [requiredSteps, setRequiredSteps] = useState<Steps[]>([]);
  const [loading, setLoading] = useState(true);

  const pop = useCallback(() => setRequiredSteps((prev) => prev.filter((_, i) => i !== 0)), []);

  const push = useCallback((step: Steps) => {
    setRequiredSteps((prev) => [step, ...prev]);
  }, []);

  // When done, go to "the app"
  useEffect(() => {
    if (!loading && requiredSteps.length === 0) {
      navigate('/');
    }
  }, [loading, navigate, requiredSteps.length]);

  const stepProps = useMemo<FlowProps>(
    () => ({
      next: pop,
      push,
      userEmail,
      user,
      memberFields,
    }),
    [memberFields, pop, push, user, userEmail],
  );

  const steps = useMemo<Record<Steps, JSX.Element>>(
    () => ({
      'not-started': <RegisterLanding {...stepProps} />,
      'set-account-details': <RegisterDetails {...stepProps} />,
      'set-password': <SetPassword {...stepProps} />,
      '2fa-info': <TwoFAInfo {...stepProps} />,
      'set-2fa': <Set2FA {...stepProps} />,
    }),
    [stepProps],
  );

  useEffect(() => {
    if (!tokenValid || !initialLoad.current) {
      return;
    }

    initialLoad.current = false;
    (hasToken ? logout() : Promise.resolve()).then(() => {
      setLoading(true);
      if (hasToken) {
        BaseService.recreateHttpClient(token);
      }

      AuthService.getRegistrationFlowStatus().then((res) => {
        if (hasToken) {
          BaseService.clearToken();
        }

        if (accountId) {
          setRegistrationClientId(accountId);
        }

        setUser(res.data.user);
        setUserEmail(res.data.user.email || '');
        const availableMemberFields = (res.data.memberFields || defaultMemberFields.filter((field) => field.isRequired && field.isEditable)).filter(
          (field) => field.key !== 'role',
        );

        setMemberFields(availableMemberFields);

        const requiredActionsFromServerTemp: (keyof typeof Checkpoints)[] = res.data.registrationActions.split(',') as (keyof typeof Checkpoints)[];

        const checkpointsToDo = CheckpointsOrder.filter((x) => requiredActionsFromServerTemp.includes(x));
        const stepsToDo = checkpointsToDo.map((x) => Checkpoints[x]).flat();
        setRequiredSteps(stepsToDo);
        setLoading(false);
      });
    });
  }, [accountId, hasToken, logout, setRegistrationClientId, token, tokenValid]);

  return tokenValid ? (
    <PageLoader loading={loading}>{steps[requiredSteps[0]]}</PageLoader>
  ) : (
    <>
      <Heading size={HeadingSize.H1}>{t('registration.expired-link.heading')}</Heading>
      <div>{t('registration.expired-link.subheading')}</div>
    </>
  );
};

export default RegisterFlow;
