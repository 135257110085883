import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { Option } from '../Option';
import ClientTagService from '../../services/ClientTagService';
import { ClientTagResponse, ClientTagType } from '../../models/ClientTag';
import LanguageUtils from '../../utils/LanguageUtils';
import { useTranslation } from 'react-i18next';
import useFetchClientTags from '../../hooks/useFetchClientTags';
import { useCurrentClient } from '../../global-state/Clients';

type Props = {
  onTagsChanged: (tags: ClientTagResponse[]) => void;
  label?: string;
  placeholder?: string;
  className?: string;
  selectedTagIds?: string[];
  type?: ClientTagType;
  isMulti?: boolean;
  helpText?: string;
  required?: boolean;
  error?: string;
  errorState?: boolean;
  disabled?: boolean;
};

const TagSelector: FC<Props> = (props) => {
  const {
    onTagsChanged,
    label,
    placeholder,
    className,
    selectedTagIds: value,
    isMulti = true,
    type = ClientTagType.ContactGroup,
    helpText,
    required,
    error,
    errorState,
    disabled,
  } = props;
  const [selectedTags, setSelectedTags] = useState<Option<string, string>[]>([]);

  const {
    i18n: { language },
  } = useTranslation();
  const currentClient = useCurrentClient((x) => x.value);

  const { data: tags, refetch: refetchTags, isLoading: loadingTags } = useFetchClientTags([type]);

  const tagOptions = useMemo(() => {
    return tags?.map((x) => ({ id: x.id, text: LanguageUtils.getTranslation('name', x.translations), value: x.id })) ?? [];
  }, [tags]);

  useEffect(() => {
    if (!value?.length || loadingTags) return;

    setSelectedTags((prev) => {
      let changed = false;
      value.forEach((x) => {
        if (!prev.find((o) => o.id === x)) {
          changed = true;
          return;
        }
      });
      if (!changed) return prev;

      return tagOptions.filter((x) => !!value.find((o) => o === x.id));
    });
  }, [value, tagOptions, loadingTags]);

  const onCreateTag = useCallback(
    (value: string) => {
      ClientTagService.createTag(currentClient!.id, { translations: { [language]: { name: value } }, type: type }).then((res) => {
        refetchTags();
        setSelectedTags((prev) => [...prev, { id: res.data.id, text: value, value: res.data.id }]);

        onTagsChanged(
          [...selectedTags, { id: res.data.id, text: value, value: res.data.id }].map((x) => [...tags!, res.data].find((o) => o.id === x.id)!),
        );
      });
    },
    [currentClient, language, onTagsChanged, refetchTags, selectedTags, tags, type],
  );

  const onRemove = useCallback(
    (item: Option<string, string>) => {
      setSelectedTags((prev) => prev.filter((x) => x !== item));
      onTagsChanged(selectedTags.filter((x) => x !== item).map((x) => tags!.find((o) => o.id === x.id)!));
    },
    [onTagsChanged, selectedTags, tags],
  );

  const onChange = useCallback(
    (value: Option<string, string> | Option<string, string>[] | null) => {
      if (!value) {
        setSelectedTags([]);
        onTagsChanged([]);
        return;
      }

      const updatedTags = Array.isArray(value) ? value : [value];
      setSelectedTags(updatedTags);

      const mappedTags = updatedTags.map((x) => tags?.find((o) => o.id === x.id)).filter((tag): tag is NonNullable<typeof tag> => !!tag);
      onTagsChanged(mappedTags);
    },
    [onTagsChanged, tags],
  );

  return (
    <>
      <DropdownSelect
        className={className}
        value={selectedTags}
        onChange={onChange}
        options={tagOptions}
        allowCreation
        isMulti={isMulti}
        onCreateOption={onCreateTag}
        onRemove={onRemove}
        autoAddChars={[';']}
        label={label}
        placeholder={placeholder}
        required={required}
        error={error}
        errorState={errorState}
        disabled={disabled}
      />

      <span className="text-dpm-14">{helpText}</span>
    </>
  );
};

export default TagSelector;
