/* eslint-disable @typescript-eslint/no-explicit-any */
import LanguageUtils from './LanguageUtils';
import { Translations } from '../models/Translation';

export const excelColumnToIndex = (column: string | null) => {
  if (!column) return null;

  const letters = column.split('').reverse();
  let index = 0;

  for (let i = 0; i < letters.length; i++) {
    const letter = letters[i];
    const charCode = letter.charCodeAt(0) - 'A'.charCodeAt(0) + 1;
    index += charCode * Math.pow(26, i);
  }

  return index - 1;
};

export const indexToExcelColumn = (index: number | null) => {
  if (index == null) return '';

  let column = '';

  while (index >= 0) {
    const charCode = (index % 26) + 'A'.charCodeAt(0);
    column = String.fromCharCode(charCode) + column;
    index = Math.floor(index / 26) - 1;
  }

  return column;
};

class StringUtils {
  static toLowerDashed(text: string, limit?: number): any {
    const result = text.replace(/\s+/g, '-').toLowerCase();
    return limit ? result.substring(0, limit) : result;
  }

  public static truncate(text: string, limit: number, ending = '...'): string {
    return text.length > limit ? `${text.substring(0, limit)}${ending}` : text;
  }

  public static hasCountryCode(phoneNumber: string) {
    const countryCodeRegex = /^\+\d{1,3}/;

    return countryCodeRegex.test(phoneNumber);
  }

  public static addLeadingZeros(number: number) {
    if (number === null || number === undefined || isNaN(number)) {
      return '';
    }

    return number.toString().padStart(3, '0');
  }

  public static makePrefixWithNumber(prefix: string | undefined, number: number, translations: Translations<'prefix'>) {
    prefix = LanguageUtils.getTranslation('prefix', translations);
    return `${prefix ? `${prefix}-` : ''}${this.addLeadingZeros(number)}`;
  }

  public static highlightText(text: string, toHighlight?: string) {
    if (!toHighlight) return [text];

    const escapedHighlight = toHighlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedHighlight})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === toHighlight.toLowerCase() ? (
        <span key={index} className="text-accent-1 font-medium">
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      ),
    );
  }
}

export default StringUtils;
