/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { DndContext, DragOverEvent, DragOverlay, DragStartEvent, closestCenter, useDroppable } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { defaultMemberFields, MemberField } from '../../models/ClientMemberFields';
import LockIcon from '../shared/icon/LockIcon';
import DragHandleIcon from '../shared/icon/DragHandleIcon';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';

type OrgMemberFieldsModalProps = {
  open: boolean;
  onClose: () => void;
  onSave: (config: MemberField[]) => void;
  config: MemberField[];
};

const DroppableContainer = ({ id, children }: { id: string; children: React.ReactNode }) => {
  const { setNodeRef } = useDroppable({ id });
  return (
    <div ref={setNodeRef} className="p-2" style={{ minHeight: '40px' }}>
      {children}
    </div>
  );
};

const SortableItem = ({ id, children, parentGroup }: { id: string; children: React.ReactNode; isLocked: boolean; parentGroup: string }) => {
  const { attributes, listeners, setNodeRef, transition } = useSortable({ id, data: { type: parentGroup } });
  const style = {
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="relative mb-2">
      {children}
    </div>
  );
};

const PlaceholderItem = () => {
  return <div className="mb-2 rounded border-2 border-dashed border-gray-300" style={{ height: '40px' }} />;
};

const group = {
  requiredFields: 'requiredFields',
  optionalFields: 'optionalFields',
  unusedFields: 'unusedFields',
};

const OrgMemberFieldsModal: FC<OrgMemberFieldsModalProps> = ({ open, onClose, onSave, config }) => {
  const { t } = useTranslation(['organisation', 'common']);
  const [activeItem, setActiveItem] = useState<MemberField | null>(null);
  const [activeGroup, setActiveGroup] = useState<keyof typeof group | null>(null);
  const lockedFields = useMemo(() => defaultMemberFields.filter((field) => field.isLocked), []);
  const [placeholderIndex, setPlaceholderIndex] = useState<number | null>(null);
  const [isDirty, setIsDirty] = useState(false);

  // Ensure locked fields are always in requiredFields
  const initialRequiredFields = useMemo(
    () => [...config.filter((field) => field.isRequired), ...lockedFields.filter((field) => !config.some((f) => f.key === field.key))],
    [config, lockedFields],
  );

  const [requiredFields, setRequiredFields] = useState<MemberField[]>([]);
  const [optionalFields, setOptionalFields] = useState<MemberField[]>([]);
  const [unusedFields, setUnusedFields] = useState<MemberField[]>([]);

  useEffect(() => {
    if (open) return;

    const optionalFields = config.filter((field) => !field.isRequired && !lockedFields.some((locked) => locked.key === field.key));
    setRequiredFields(initialRequiredFields);
    setOptionalFields(optionalFields);
    setUnusedFields(
      defaultMemberFields.filter(
        (field) =>
          !initialRequiredFields.some((f) => f.key === field.key) &&
          !optionalFields.some((f) => f.key === field.key) &&
          !lockedFields.some((locked) => locked.key === field.key),
      ),
    );
    setIsDirty(false);
  }, [config, initialRequiredFields, lockedFields, open]);

  const onDragStart = ({ active }: DragStartEvent) => {
    const activeField =
      requiredFields.find((field) => field.key === active.id) ||
      optionalFields.find((field) => field.key === active.id) ||
      unusedFields.find((field) => field.key === active.id);
    setActiveItem(activeField || null);
  };

  const onDragOver = ({ active, over }: DragOverEvent) => {
    if (!over || over.id === active.id) {
      setPlaceholderIndex(null);
      return;
    }
    setActiveGroup(over.data.current?.type);
    const targetList = [requiredFields, optionalFields, unusedFields].find((list) => list.some((field) => field.key === over.id));

    if (targetList) {
      const index = targetList.findIndex((field) => field.key === over.id);
      setPlaceholderIndex(index >= 0 ? index : null);
    }
  };

  const onDragEnd = ({ active, over }: any) => {
    setActiveItem(null);
    if (!over) {
      setPlaceholderIndex(null);
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    // Determine source and destination containers
    const sourceContainer = [requiredFields, optionalFields, unusedFields].find((list) => list.some((field) => field.key === activeId));

    let destinationContainer;
    if (overId === 'optional-placeholder') {
      destinationContainer = optionalFields;
    } else if (overId === 'unused-placeholder') {
      destinationContainer = unusedFields;
    } else {
      destinationContainer = [requiredFields, optionalFields, unusedFields].find((list) => list.some((field) => field.key === overId));
    }

    const activeField = sourceContainer?.find((field) => field.key === activeId);

    if (!sourceContainer || !destinationContainer || !activeField) {
      setPlaceholderIndex(null);
      return;
    }

    // Prevent locked items from being moved to another category
    if (activeField.isLocked && sourceContainer !== destinationContainer) {
      setPlaceholderIndex(null);
      return;
    }

    setIsDirty(true);

    // Sort list
    if (sourceContainer === destinationContainer) {
      const oldIndex = sourceContainer.findIndex((field) => field.key === activeId);
      const newIndex = sourceContainer.findIndex((field) => field.key === overId);

      const updatedContainer = arrayMove(sourceContainer, oldIndex, newIndex);

      if (sourceContainer === requiredFields) setRequiredFields(updatedContainer);
      if (sourceContainer === optionalFields) setOptionalFields(updatedContainer);
      if (sourceContainer === unusedFields) setUnusedFields(updatedContainer);
    }
    // Move item to another category
    else {
      const updatedSource = sourceContainer.filter((field) => field.key !== activeId);
      const updatedDestination = [...destinationContainer];

      if (placeholderIndex !== null) {
        updatedDestination.splice(placeholderIndex, 0, activeField);
      } else {
        updatedDestination.push(activeField);
      }

      if (sourceContainer === requiredFields) setRequiredFields(updatedSource);
      if (sourceContainer === optionalFields) setOptionalFields(updatedSource);
      if (sourceContainer === unusedFields) setUnusedFields(updatedSource);

      if (destinationContainer === requiredFields) setRequiredFields(updatedDestination);
      if (destinationContainer === optionalFields) setOptionalFields(updatedDestination);
      if (destinationContainer === unusedFields) setUnusedFields(updatedDestination);
    }
    setPlaceholderIndex(null);
  };

  const handleSave = useCallback(() => {
    onSave([...requiredFields.map((x) => ({ ...x, isRequired: true })), ...optionalFields.map((x) => ({ ...x, isRequired: false }))]);
    onClose();
    setIsDirty(false);
  }, [onClose, onSave, optionalFields, requiredFields]);

  const onReset = useCallback(() => {
    setRequiredFields(defaultMemberFields.filter((field) => field.isLocked));
    setOptionalFields([]);
    setUnusedFields(defaultMemberFields.filter((field) => !field.isLocked));
    setIsDirty(true);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        open,
        onClose,
        modalWidth: 'w-1/4',
        position: 'end',
        height: '100vh',
        maxHeight: '100vh',
      }}
    >
      <StandardModal
        title={t('permissions.member-fields-config.modal.title')}
        subTitle={t('permissions.member-fields-config.modal.description')}
        onCancelClick={onClose}
        onConfirmClick={handleSave}
        confirmDisabled={!isDirty}
      >
        <div>
          <Button
            type={ButtonType.SECONDARY}
            onClick={onReset}
            className="mb-4"
            title={t('permissions.member-fields-config.reset-button')}
            size={ButtonSize.S}
          >
            {t('permissions.member-fields-config.reset-button')}
          </Button>

          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
          >
            {/* Drag Overlay */}
            <DragOverlay style={{ position: 'absolute', left: '20px' }}>
              {activeItem ? (
                <div className="text-dpm-14 flex min-h-[16px] w-80 cursor-grabbing items-center justify-center rounded-xl border-2 bg-white bg-opacity-90 p-2 shadow-lg">
                  <span>{t(`permissions.member-fields-config.fields.${activeItem.key}` as any)}</span>
                </div>
              ) : null}
            </DragOverlay>
            {/* Render Required Fields */}
            <div className={`h-[calc(100vh_-_18rem)] overflow-y-auto`}>
              <div>
                <span>{t('permissions.member-fields-config.required', { count: requiredFields.length })}</span>
                <SortableContext items={requiredFields.map((field) => field.key)}>
                  <div className="ml-2 mt-2">
                    {requiredFields.map((field, index) => {
                      return (
                        <Fragment key={field.key}>
                          {placeholderIndex === index && activeGroup === group.requiredFields && <PlaceholderItem />}
                          <SortableItem id={field.key} isLocked={field.isLocked} parentGroup={group.requiredFields}>
                            <DragHandleIcon className="text-gray-2 mr-2 h-4 w-4 cursor-grab" />
                            {field.isLocked && <LockIcon className="text-gray-3 mr-2 h-4 w-4" />}
                            <span className={!field.isEditable ? 'text-gray-3' : ''}>
                              {t(`permissions.member-fields-config.fields.${field.key}` as any)}
                            </span>
                          </SortableItem>
                        </Fragment>
                      );
                    })}
                    {placeholderIndex === requiredFields.length && activeGroup === group.requiredFields && <PlaceholderItem />}
                  </div>
                </SortableContext>
              </div>

              {/* Render Optional Fields */}
              <div className="relative">
                <span>{t('permissions.member-fields-config.optional', { count: optionalFields.length })}</span>
                <SortableContext items={optionalFields.map((field) => field.key)} strategy={verticalListSortingStrategy}>
                  <div className="ml-2 mt-2">
                    {optionalFields.length === 0 && (
                      <DroppableContainer id="optional-placeholder">{t('organisation:permissions.member-fields-config.empty')}</DroppableContainer>
                    )}
                    {optionalFields.map((field, index) => {
                      return (
                        <Fragment key={field.key}>
                          {placeholderIndex === index && activeGroup === group.optionalFields && <PlaceholderItem />}
                          <SortableItem id={field.key} isLocked={false} parentGroup={group.optionalFields}>
                            <DragHandleIcon className="text-gray-2 mr-2 h-4 w-4" />
                            <span>{t(`permissions.member-fields-config.fields.${field.key}` as any)}</span>
                          </SortableItem>
                        </Fragment>
                      );
                    })}
                    {placeholderIndex === optionalFields.length && activeGroup === group.optionalFields && <PlaceholderItem />}
                  </div>
                </SortableContext>
              </div>

              {/* Render Unused Fields */}
              <div>
                <span>{t('permissions.member-fields-config.unused', { count: unusedFields.length })}</span>
                <SortableContext items={unusedFields.map((field) => field.key).concat(['unused-placeholder'])}>
                  <div className="ml-2 mt-2">
                    {unusedFields.length === 0 && (
                      <DroppableContainer id="unused-placeholder">{t('organisation:permissions.member-fields-config.empty')}</DroppableContainer>
                    )}
                    {unusedFields.map((field, index) => {
                      return (
                        <Fragment key={field.key}>
                          {placeholderIndex === index && activeGroup === group.unusedFields && <PlaceholderItem />}
                          <SortableItem id={field.key} isLocked={false} parentGroup={group.unusedFields}>
                            <DragHandleIcon className="text-gray-2 mr-2 h-4 w-4" />
                            <span>{t(`permissions.member-fields-config.fields.${field.key}` as any)}</span>
                          </SortableItem>
                        </Fragment>
                      );
                    })}
                    {placeholderIndex === unusedFields.length && activeGroup === group.unusedFields && <PlaceholderItem />}
                  </div>
                </SortableContext>
              </div>
            </div>
          </DndContext>
        </div>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default OrgMemberFieldsModal;
