import { useQuery } from '@tanstack/react-query';
import { ClientTagType } from '../models/ClientTag';
import ClientTagService from '../services/ClientTagService';
import { useCurrentClient, useRegistrationClientId } from '../global-state/Clients';

const useFetchClientTags = (types: ClientTagType[] = [ClientTagType.ContactGroup]) => {
  const currentClient = useCurrentClient((x) => x.value);
  const registrationClientId = useRegistrationClientId((x) => x.value);
  const currentClientId = currentClient?.id || registrationClientId;
  return useQuery({
    queryKey: ['clientTags', currentClientId, types],
    staleTime: Infinity,
    queryFn: () => {
      return ClientTagService.getAllTags(currentClientId!, { types: types, pageSize: 9999 }).then((res) => res.data);
    },
    retry: false,
  });
};

export default useFetchClientTags;
